import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

import { Container } from "../components/global"

const Impressum = () => (
  <Layout className="container">
    <div className="description">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>Impressum</h1>
      <br />
      <p>
        <strong>Kontakt-Adresse</strong>
      </p>
      <p>
        Christian Michels
        <br />
        Dubsstrasse 23
        <br />
        8003 Z&uuml;rich
        <br />
        Schweiz
      </p>
      <p>
        E-Mail:
        <br />
        christian.michels@4arge.com
      </p>
      <br />
      <p>
        <strong>Vertretungsberechtigte Person(en)</strong>
      </p>
      <p>Christian Michels, Partner</p>
      <p>Nadine Heertsch, Partner</p>
      <br />
      <p>
        <strong>Handelsregister-Eintrag</strong>
      </p>
      <p>
        Eingetragener Firmenname: 4arge GmbH <br />
        Handelsregister Nr: CH-020.4.048.393-4
      </p>
      <br />
      <p>
        <strong>Mehrwertsteuer-Nummer</strong>
        <br />
        CHE-391.009.700
      </p>
      <br />
      <p>
        <strong>Haftungsausschluss</strong>
      </p>
      <p>
        Der Autor &uuml;bernimmt keinerlei Gew&auml;hr hinsichtlich der
        inhaltlichen Richtigkeit, Genauigkeit, Aktualit&auml;t,
        Zuverl&auml;ssigkeit und Vollst&auml;ndigkeit der Informationen.
      </p>
      <br />
      <p>
        Haftungsanspr&uuml;che gegen den Autor wegen Sch&auml;den materieller
        oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.
        Nichtnutzung der ver&ouml;ffentlichten Informationen, durch Missbrauch
        der Verbindung oder durch technische St&ouml;rungen entstanden sind,
        werden ausgeschlossen.
      </p>
      <br />
      <p>
        Alle Angebote sind unverbindlich. Der Autor beh&auml;lt es sich
        ausdr&uuml;cklich vor, Teile der Seiten oder das gesamte Angebot ohne
        besondere Ank&uuml;ndigung zu ver&auml;ndern, zu erg&auml;nzen, zu
        l&ouml;schen oder die Ver&ouml;ffentlichung zeitweise oder
        endg&uuml;ltig einzustellen.
      </p>
      <p>&nbsp;</p>
      <br />
      <p>
        <strong>Haftungsausschluss f&uuml;r Links</strong>
      </p>
      <p>
        Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
        Verantwortungsbereichs. Es wird jegliche Verantwortung f&uuml;r solche
        Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
        erfolgen auf eigene Gefahr des jeweiligen Nutzers.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Urheberrechte</strong>
      </p>
      <p>
        Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
        anderen Dateien auf dieser Website, geh&ouml;ren ausschliesslich{" "}
        <strong>der Firma 4arge GmbH</strong> oder den speziell genannten
        Rechteinhabern. F&uuml;r die Reproduktion jeglicher Elemente ist die
        schriftliche Zustimmung des Urheberrechtstr&auml;gers im Voraus
        einzuholen.
      </p>
      <br />
      <br />
      Quelle:{" "}
      <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">
        SwissAnwalt
      </a>
      <br />
      <br />
      <br />
      <Link to="/">Go back to the homepage</Link>
      <br />
      <br />
    </div>
  </Layout>
)

export default Impressum
